body{
	background-color: #f0f0f0;
}

.tp.login-screen{
	// height: 75vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;

	> img.tp.logo{
		display: block;
		height: 50px;
		margin: 15px auto 30px auto;

		@media screen and (max-width: 415px){
			margin-top: 30px;
		}
	}

	.fr.groupper{
		min-height: 400px;
		max-width: 400px !important;
		width: 100%;
		.head{
			height: auto;
			&.logo{
				justify-content: center;
				padding: 15px 10px;
				> img{
					height: 40px;
				}
			}
			> .fr.header{
				margin: 0;
				width: 100%;
				text-align: center;
				padding: 3px;
				.sub.header{
					font-size: 14px;
					text-transform: none;
				}
			}
		}
		> .contents{
			// min-height: calc(400px - 44px - 58px - 72px);
			// min-height: calc(320px - 44px);
			&.full{
				min-height: 360px;
				// margin-top: -20px;
				display: flex;
				justify-content: center;
				align-items: center;
				> .fr.header{
					margin: 0;
				}
			}
			.fr.header > i.icon{
				display: block;
				margin: auto !important;
				margin-bottom: 5px !important;
				line-height: 1em;
			}
			&.semi{
				min-height: 300px;
				flex-direction: column;
			}
			// display: flex;
			// flex-direction: column;
			// justify-content: center;

			.forgot{
				text-align: center;
				margin-top: 15px;
				.fr.button{
					color: gray !important;
					font-weight: normal;
					&:hover{
						font-weight: bold;
						color: white !important;
					}
				}
			}
		}

		.fr.apple-button{
			width: 100%;
			border-radius: 100px;
			margin-top: 3px;
		}

		> .create{
			display: flex;
			align-items: center;
			justify-content: center;
			// margin-bottom: -15px;
			// margin-top: -15px;
			color: gray;
			
			> .fr.button{
				// color: #c82a02 !important;
				// &:hover{
				// 	color: white !important;
				// }
				margin-left: 5px;
			}
		}

		.ar.captcha{
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 10px;
		}
	}

	@media screen and (max-width: 415px){
		display: block;
	}
}

.fr.groupper{
	> .divider{
		&.dashed{
			border-color: rgba(0,0,0,.15);
		}
		&.text{
			border-top: none;
			height: auto;
			display: flex;
			align-items: center;
			.text{
				margin: 0 15px;
				color: gray;
			}
			.line{
				flex-grow: 100;
				height: 1px;
				border-top: 1px solid rgba(0, 0, 0, 0.1);
			}
		}
	}
}